// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-index-js": () => import("/opt/build/repo/src/pages/archive/index.js" /* webpackChunkName: "component---src-pages-archive-index-js" */),
  "component---src-pages-archive-viewer-js": () => import("/opt/build/repo/src/pages/archive/viewer.js" /* webpackChunkName: "component---src-pages-archive-viewer-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sponsorship-inquiry-sent-js": () => import("/opt/build/repo/src/pages/sponsorship-inquiry-sent.js" /* webpackChunkName: "component---src-pages-sponsorship-inquiry-sent-js" */),
  "component---src-pages-sponsorship-js": () => import("/opt/build/repo/src/pages/sponsorship.js" /* webpackChunkName: "component---src-pages-sponsorship-js" */),
  "component---src-pages-subscribed-js": () => import("/opt/build/repo/src/pages/subscribed.js" /* webpackChunkName: "component---src-pages-subscribed-js" */),
  "component---src-pages-unsubscribed-js": () => import("/opt/build/repo/src/pages/unsubscribed.js" /* webpackChunkName: "component---src-pages-unsubscribed-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

